import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MDCATLandingPage from './MDCATLandingPage.tsx';
import HowItWorksPage from './HowItWorksPage.tsx';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MDCATLandingPage />} />
                <Route path="/how-it-works" element={<HowItWorksPage />} />
            </Routes>
        </Router>
    );
};

export default App;
