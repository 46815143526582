// Import required modules
import React from 'react';
import {
    ChevronRight,
    Brain,
    Layers,
    ListOrdered,
    GraduationCap
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';

const HowItWorksPage = () => {
    const navigate = useNavigate();

    const steps = [
        {
            icon: <Layers className="w-12 h-12 text-blue-600" />,
            title: "Step 1: Personalized Profile",
            description: "Create your profile and let our AI understand your strengths and areas of improvement."
        },
        {
            icon: <ListOrdered className="w-12 h-12 text-green-600" />,
            title: "Step 2: Adaptive Learning",
            description: "Receive tailored MCQs and practice sets designed to optimize your learning."
        },
        {
            icon: <GraduationCap className="w-12 h-12 text-purple-600" />,
            title: "Step 3: Progress Tracking",
            description: "Monitor your performance with real-time analytics and recommendations."
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white">
            {/* Navigation */}
            <nav className="sticky top-0 z-50 bg-white/90 backdrop-blur-md shadow-sm">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <div className="flex items-center space-x-2 md:space-x-4">
                        <Brain className="w-8 h-8 md:w-12 md:h-12 text-blue-600" />
                        <h1 className="text-xl md:text-2xl font-bold text-blue-800">MDCAT.ai</h1>
                    </div>
                    <div className="hidden md:flex space-x-4 items-center">
                        <a
                            href="#steps"
                            className="text-blue-700 hover:text-blue-900 transition"
                        >
                            Steps
                        </a>
                        <button
                            onClick={() => navigate('/')}
                            className="bg-blue-600 text-white text-sm md:text-base px-3 md:px-4 py-2 rounded-full hover:bg-blue-700 transition"
                        >
                            Go Back
                        </button>
                    </div>
                </div>
            </nav>

            {/* Header Section */}
            <header className="container mx-auto px-4 py-12 md:py-16 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                <div>
                    <h2 className="text-3xl md:text-5xl font-bold text-blue-900 mb-4 md:mb-6 leading-snug">
                        Discover How MDCAT.ai Works
                    </h2>
                    <p className="text-base md:text-xl text-gray-600 mb-6 md:mb-8">
                        Understand our step-by-step approach to help you excel in your MDCAT preparation.
                    </p>
                    <button
                        onClick={() => navigate('/')}
                        className="bg-blue-600 text-white text-sm md:text-base px-4 md:px-6 py-3 rounded-lg hover:bg-blue-700 transition flex items-center"
                    >
                        Back to Home <ChevronRight className="ml-2" />
                    </button>
                </div>
                    <div className="relative flex justify-center md:block">
                        <div className="w-full max-w-md">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=D0Pd1sZp9EU&t=68s"
                                width="100%"
                                height="280px"
                                controls
                                className="rounded-xl shadow-2xl transform hover:scale-105 transition duration-300"
                            />
                        </div>
                </div>
            </header>

            {/* Steps Section */}
            <section
                id="steps"
                className="container mx-auto px-4 py-12 md:py-16 bg-white"
            >
                <div className="text-center mb-8 md:mb-12">
                    <h3 className="text-2xl md:text-4xl font-bold text-blue-900 mb-4">
                        Simplified in 3 Easy Steps
                    </h3>
                    <p className="text-sm md:text-xl text-gray-600 max-w-xl mx-auto">
                        Get started quickly and make the most of your preparation journey with our AI-powered platform.
                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition transform hover:-translate-y-2"
                        >
                            {step.icon}
                            <h4 className="text-lg md:text-xl font-bold mt-4 mb-2">
                                {step.title}
                            </h4>
                            <p className="text-sm md:text-base text-gray-600">
                                {step.description}
                            </p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-blue-900 text-white py-8 md:py-12">
                <div className="container mx-auto px-4 text-center">
                    <h4 className="text-xl md:text-3xl font-bold mb-4">
                        Join the MDCAT Revolution
                    </h4>
                    <p className="text-sm md:text-xl mb-6 md:mb-8">
                        Be the first to transform your medical entrance exam preparation
                    </p>
                    <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
                        <button
                            className="bg-white text-blue-900 px-6 py-3 rounded-full hover:bg-blue-100 transition relative group text-sm md:text-base"
                            onClick={() => alert('Coming soon! Join our waitlist.')}
                        >
                            Play Store
                            <div className="absolute bottom-0 left-0 w-full p-2 bg-black text-white text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Coming Soon! Join our waitlist, we’ll notify you.
                            </div>
                        </button>
                        <button
                            className="bg-white text-blue-900 px-6 py-3 rounded-full hover:bg-blue-100 transition relative group text-sm md:text-base"
                            onClick={() => alert('Coming soon! Join our waitlist.')}
                        >
                            App Store
                            <div className="absolute bottom-0 left-0 w-full p-2 bg-black text-white text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Coming Soon! Join our waitlist, we’ll notify you.
                            </div>
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default HowItWorksPage;
