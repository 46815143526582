import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';

import {
    ChevronRight,
    CheckCircle,
    Book,
    Brain,
    Target,
    Rocket,
    MessageCircleQuestion,
    BarChart3,
    CogIcon,
    Menu,
    X
} from 'lucide-react';

const MDCATLandingPage = () => {
    const [email, setEmail] = useState('');
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [highlightForm, setHighlightForm] = useState(false);
    const emailInputRef = useRef(null); // Create a ref for the email input

    const features = [
        {
            icon: <CogIcon className="w-10 h-10 md:w-12 md:h-12 text-blue-600" />,
            title: "Adaptive AI Learning",
            description: "Personalized MCQs that adapt to your skill level"
        },
        {
            icon: <BarChart3 className="w-10 h-10 md:w-12 md:h-12 text-green-600" />,
            title: "Performance Analytics",
            description: "Detailed insights into your preparation progress"
        },
        {
            icon: <MessageCircleQuestion className="w-10 h-10 md:w-12 md:h-12 text-purple-600" />,
            title: "AI Doubt Resolver",
            description: "24/7 instant doubt clearing chatbot"
        },
        {
            icon: <Book className="w-10 h-10 md:w-12 md:h-12 text-yellow-600" />,
            title: "Comprehensive Syllabus Coverage",
            description: "Complete preparation across all topics"
        },
        {
            icon: <Rocket className="w-10 h-10 md:w-12 md:h-12 text-red-600" />,
            title: "Detailed Analytics & Monitoring",
            description: "Track your progress and refine your strategy"
        },
        {
            icon: <Target className="w-10 h-10 md:w-12 md:h-12 text-teal-600" />,
            title: "Tailored Learning Experience",
            description: "Customized plans to maximize your success"
        }
    ];

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setSubmissionStatus('');

        try {
            const response = await fetch('https://mdcat-backend.vercel.app/api/waitlist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                setSubmissionStatus('success');
                setEmail('');
                alert(data.message || 'Successfully joined waitlist!');
            } else {
                setSubmissionStatus('error');
                alert(data.message || 'Failed to join waitlist');
            }
        } catch (error) {
            setSubmissionStatus('error');
            console.error('Error submitting email:', error);
            alert('Network error. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const highlightEmailInput = () => {
        // Highlight the email input box only
        if (emailInputRef.current) {
            emailInputRef.current.classList.add('ring-4', 'ring-blue-1000', 'ring-opacity-50'); // Darker blue

            // Remove the highlight after two seconds
            setTimeout(() => {
                if (emailInputRef.current) {
                    emailInputRef.current.classList.remove('ring-4', 'ring-blue-900', 'ring-opacity-50');
                }
            }, 2000);
        }

        // Optional alert for feedback
        alert('Please enter your email to join the waitlist!');
    };



    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white">
            {/* Navigation */}
            <nav className="sticky top-0 z-50 bg-white/90 backdrop-blur-md shadow-sm">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <div className="flex items-center space-x-2 md:space-x-4">
                        <Brain className="w-8 h-8 md:w-12 md:h-12 text-blue-600" />
                        <h1 className="text-xl md:text-2xl font-bold text-blue-800">MDCAT.ai</h1>
                    </div>

                    {/* Desktop Navigation */}
                    <div className="hidden md:flex space-x-4 items-center">
                        <a
                            href="#features"
                            className="text-blue-700 hover:text-blue-900 transition"
                        >
                            Features
                        </a>
                        <a
                            href="/how-it-works"
                            className="text-blue-700 hover:text-blue-900 transition"
                        >
                            How It Works
                        </a>
                        <button
                            onClick={highlightEmailInput}  // Update to call highlightEmailInput
                            className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-900 transition"
                        >
                            Join Waitlist
                        </button>

                    </div>

                    {/* Mobile Menu Toggle */}
                    <div className="md:hidden">
                        <button
                            onClick={toggleMobileMenu}
                            className="text-blue-700 hover:text-blue-900"
                        >
                            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
                        </button>
                    </div>
                </div>

                {/* Mobile Navigation Dropdown */}
                {isMobileMenuOpen && (
                    <div className="md:hidden bg-white shadow-md">
                        <div className="flex flex-col items-center space-y-4 py-4">
                            <a
                                href="#features"
                                className="text-blue-700 hover:text-blue-900 transition"
                                onClick={toggleMobileMenu}
                            >
                                Features
                            </a>
                            <a
                                href="/how-it-works"
                                className="text-blue-700 hover:text-blue-900 transition"
                                onClick={toggleMobileMenu}
                            >
                                How It Works
                            </a>
                            <button
                                onClick={() => {
                                    toggleMobileMenu();
                                }}
                                className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition"
                            >
                                Join Waitlist
                            </button>
                        </div>
                    </div>
                )}
            </nav>

            {/* Hero Section */}
            <header className="container mx-auto px-4 py-12 md:py-16 grid md:grid-cols-2 gap-10 items-center">
                <div className="text-center md:text-left">
                    <h2 className="text-3xl md:text-5xl font-bold text-blue-900 mb-4 md:mb-6 leading-tight">
                        Master MDCAT with AI-Powered Preparation
                    </h2>
                    <p className="text-base md:text-xl text-gray-600 mb-6 md:mb-8">
                        Personalized learning, adaptive MCQs, and intelligent performance tracking - all in one app.
                    </p>

                    <div
                        id="waitlist"
                        className={`scroll-mt-0 ${highlightForm ? 'ring-4 ring-yellow-500 ring-opacity-50' : ''
                            }`}
                    >
                        <form
                            onSubmit={handleEmailSubmit}
                            className="flex flex-col space-y-2 max-w-md mx-auto md:mx-0"
                        >
                            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
                                <input
                                    ref={emailInputRef} // Attach the ref to the email input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full px-4 py-3 border-2 border-blue-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                />

                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className="w-full md:w-auto bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition flex items-center justify-center disabled:opacity-50"
                                >
                                    {isLoading ? 'Submitting...' : 'Notify Me'}
                                    <ChevronRight className="ml-2" />
                                </button>
                            </div>
                            {submissionStatus === 'success' && (
                                <div className="text-green-600 flex items-center justify-center md:justify-start">
                                    <CheckCircle className="mr-2" />
                                    Thank you! We'll notify you soon.
                                </div>
                            )}
                            {submissionStatus === 'error' && (
                                <div className="text-red-600 text-center md:text-left">
                                    Oops! Something went wrong. Please try again.
                                </div>
                            )}
                        </form>
                    </div>
                </div>
                <div className="relative flex justify-center md:block">
                    <div className="w-full max-w-md">
                        <ReactPlayer
                            url="https://www.youtube.com/watch?v=D0Pd1sZp9EU&t=68s"
                            width="100%"
                            height="280px"
                            controls
                            className="rounded-xl shadow-2xl transform hover:scale-105 transition duration-300"
                        />
                    </div>
                </div>
            </header>

            {/* Features Section */}
            <section id="features" className="container mx-auto px-4 py-12 md:py-16 bg-white">
                <div className="text-center mb-8 md:mb-12">
                    <h3 className="text-3xl md:text-4xl font-bold text-blue-900 mb-3 md:mb-4">
                        Revolutionize Your MDCAT Preparation
                    </h3>
                    <p className="text-base md:text-xl text-gray-600 max-w-2xl mx-auto">
                        MDCAT.ai is not just an app, it's your personalized AI study companion designed to maximize your potential.
                    </p>
                </div>

                <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-8">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            className="bg-white p-4 md:p-6 rounded-xl shadow-lg hover:shadow-2xl transition transform hover:-translate-y-2"
                        >
                            {feature.icon}
                            <h4 className="text-lg md:text-xl font-bold mt-3 md:mt-4 mb-2">{feature.title}</h4>
                            <p className="text-sm md:text-base text-gray-600">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-blue-900 text-white py-8 md:py-12">
                <div className="container mx-auto px-4 text-center">
                    <h4 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">Join the MDCAT Revolution</h4>
                    <p className="text-base md:text-xl mb-6 md:mb-8">
                        Be the first to transform your medical entrance exam preparation
                    </p>
                    <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
                        <button
                            className="bg-white text-blue-900 px-6 py-3 rounded-full hover:bg-blue-100 transition relative group w-full md:w-auto"
                            onClick={() => alert('Coming soon! Join our waitlist.')}
                        >
                            Play Store
                            <div className="absolute bottom-0 left-0 w-full p-2 bg-black text-white text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Coming Soon! Join our waitlist, we'll notify you.
                            </div>
                        </button>
                        <button
                            className="bg-white text-blue-900 px-6 py-3 rounded-full hover:bg-blue-100 transition relative group w-full md:w-auto"
                            onClick={() => alert('Coming soon! Join our waitlist.')}
                        >
                            App Store
                            <div className="absolute bottom-0 left-0 w-full p-2 bg-black text-white text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Coming Soon! Join our waitlist, we'll notify you.
                            </div>
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default MDCATLandingPage;
